/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import {BlockScroll} from '../hooks/useLockBodyScroll'
import {LoadingContext} from '../pages'

const LoadingPage = () => {
	const {isLoaded} = useContext(LoadingContext)

	return isLoaded ? (
		<></>
	) : (
		<div className='fixed inset-0 flex items-center justify-center w-screen h-screen z-50 pointer-events-none'>
			<BlockScroll />
			<div className='flex items-center h-screen w-screen justify-center bg-slate-100 pointer-events-all'>
				<img src='/Loading.svg' alt='' className='flex items-center h-[80%] w-[60%] justify-center' />
			</div>
		</div>
	)
}

export default LoadingPage
