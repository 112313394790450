import React, {useContext, useEffect} from 'react'
import {Helmet} from 'react-helmet-async'
import Contact from '../../sections/Contact'
import {LoadingContext, NavContext} from '..'

const ContactPage = () => {
	// eslint-disable-next-line no-unused-vars
	const {setIsLoaded, isLoaded} = useContext(LoadingContext) // Use the context
	const {setHideOnScroll, setOnHeroSection} = useContext(NavContext) // Access the context

	useEffect(() => {
		// console.log('useEffect triggered on ContactPage, isLoaded:', isLoaded)

		setIsLoaded(true)
		setHideOnScroll(false)
		setOnHeroSection(false)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Helmet>
				<title>Contact Us | Knapp Cartoons</title>
				<meta
					name='description'
					content="Get in touch with Knapp Cartoons for custom cartoon caricatures, character designs, and illustrations. We're here to bring your ideas to life!"
				/>

				{/* Open Graph tags */}
				<meta property='og:title' content='Contact Us | Knapp Cartoons' />
				<meta
					property='og:description'
					content="Get in touch with Knapp Cartoons for custom cartoon caricatures, character designs, and illustrations. We're here to bring your ideas to life!"
				/>
				<meta property='og:type' content='website' />

				{/* Twitter Card tags */}
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content='Contact Us | Knapp Cartoons' />
				<meta
					name='twitter:description'
					content="Get in touch with Knapp Cartoons for custom cartoon caricatures, character designs, and illustrations. We're here to bring your ideas to life!"
				/>
			</Helmet>
			<div className='contact-page'>
				<Contact />
			</div>
		</>
	)
}

export default ContactPage
