import React, {useContext, useEffect} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet-async' // Add this import
import aboutImage from '../../images/about/About.png'
import {LoadingContext, NavContext} from '../index' // Import the context
import ScrollToTop from '../../utils/scrollToTop'

const About = () => {
	// eslint-disable-next-line no-unused-vars
	const {setIsLoaded, isLoaded} = useContext(LoadingContext) // Use the context
	const {setHideOnScroll, setOnHeroSection} = useContext(NavContext) // Access the context
	const location = useLocation()
	const navigate = useNavigate() // Initialize navigate

	useEffect(() => {
		// console.log('useEffect triggered on About, isLoaded:', isLoaded)

		setIsLoaded(true)
		setHideOnScroll(false)
		setOnHeroSection(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		navigate(location.pathname, {replace: true})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) //

	return (
		<>
			<Helmet>
				<title>Meet the Team | Knapp Cartoons</title>
				<meta
					name='description'
					content='Meet the talented cartoonists and character designers at Knapp Cartoons. We create personalized, high-quality cartoon caricatures for any occasion.'
				/>

				{/* Open Graph tags */}
				<meta property='og:title' content='Meet the Team | Knapp Cartoons' />
				<meta
					property='og:description'
					content='Meet the talented cartoonists and character designers at Knapp Cartoons. We create personalized, high-quality cartoon caricatures for any occasion.'
				/>
				<meta property='og:image' content={aboutImage} />
				<meta property='og:type' content='website' />

				{/* Twitter Card tags */}
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content='Meet the Team | Knapp Cartoons' />
				<meta
					name='twitter:description'
					content='Meet the talented cartoonists and character designers at Knapp Cartoons. We create personalized, high-quality cartoon caricatures for any occasion.'
				/>
				<meta name='twitter:image' content={aboutImage} />
			</Helmet>
			<div className='about-page mix-blend-hard-light min-h-[50vh] w-screen mt-44 sm:mt-32 md:mt-20'>
				<ScrollToTop />
				{/* <Nav defaultShow={true} /> */}
				<section className='flex flex-col items-center px-4 py-8 fade-this '>
					{' '}
					{/* Added mt-16 for margin-top */}
					<h1 className='text-3xl mb-6 font-lucky'>Meet the team!</h1>
					<div className='flex flex-col md:flex-row items-center mb-8 p-4 max-w-3xl'>
						{/* <img src='/path/to/your/image.jpg' alt='Our Team' className='w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 md:mr-8' /> */}
						<div>
							<p className='mb-4'>
								The Knapp Cartoons Team are talented cartoonists and character designers that understand the importance of bringing out the charm in every
								persona in a creative, fun, cartoony way.
								<br />
								<br />
								We work with our clients, to ensure 100% satisfaction, with a personal touch, a deep love for what we do and attention to detail.
								<br />
								We believe in a personalized approach with each art piece, creating a high-quality one-of-a-kind cartoon caricature.
								<br />
								<br />
								Prepare yourselves to laugh and have your hearts warmed, with the best gift.
								<br />
								Whether for a birthday, graduation, wedding, or any reason for celebration, a fun and heartwarming high level cartoon caricature can be the
								perfect solution.
								<br />
								<br />
								Nothing beats the creative touch of a professional cartoon artist.
								<br />
								<br />
								So if you're looking to surprise a friend or family member with a personal, unique and unforgettable gift, or simply want to add some
								personality to your social media profile, consider bringing your own caricature to life!
							</p>
							<p>
								Want to learn more or get in touch? Visit our{' '}
								<Link to='/contact?from=about' className='text-blue-600 hover:underline'>
									Contact page
								</Link>
								.
							</p>
						</div>
					</div>
					<div className='max-w-screen-xl'>
						<img
							src={aboutImage}
							alt='Horse showing the headless rider a mirror. for the giggles of-course.'
							className='max-w-[100%] w-auto max-h-[50vh]'
							// width={1200} height={800}
							layout='responsive'
							// loading='lazy'
						/>
					</div>
				</section>
			</div>
		</>
	)
}

export default About
