import React, {useRef, useState, useEffect} from 'react'
// import {toast} from 'react-toastify'
// eslint-disable-next-line no-unused-vars
import {useNotification} from '../context/NotificationContext'

const ImageLoader = ({
	onLoad,
	altTitle,
	artPNG,
	artWEBP,
	artJPG,
	className,
	style,
	placeholder,
	isLoading,
	setIsLoading,
	size = {width: 0, height: 0},
}) => {
	const imgRef = useRef(null)
	const [isFullyLoaded, setIsFullyLoaded] = useState(false)

	useEffect(() => {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'attributes' && mutation.attributeName === 'data-loaded') {
					setIsFullyLoaded(imgRef.current?.getAttribute('data-loaded') === 'true')
				}
			})
		})

		if (imgRef.current) {
			observer.observe(imgRef.current, {attributes: true})
		}

		return () => observer.disconnect()
	}, [])

	const loadingSVGPath = '/Loading.svg'
	const thumbnail = placeholder || loadingSVGPath
	const aspectRatio = size.width && size.height ? size.height / size.width : undefined
	const aspectRatioPercentage = (aspectRatio * 100).toFixed(2)
	const divStyle =
		!aspectRatio && aspectRatio !== undefined
			? {
					position: 'relative',
					width: '100%',
					paddingTop: aspectRatioPercentage + '%',
					backgroundColor: '#f0f0f0',
					overflow: 'hidden',
			  }
			: undefined

	const imgStyle =
		!aspectRatio && aspectRatio !== undefined
			? {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					objectFit: 'cover',
			  }
			: undefined

	// eslint-disable-next-line no-unused-vars
	const {setToast} = useNotification()
	const handleImageLoad = (e) => {
		if (onLoad) onLoad(e)
		if (setIsLoading) setIsLoading(false)
		setIsFullyLoaded(true) // Set loaded image state to true
	}

	return (
		<div style={divStyle} className='relative'>
			<picture style={imgStyle}>
				{artWEBP && <source type='image/webp' srcSet={artWEBP} />}
				{artPNG && <source type='image/png' srcSet={artPNG} />}
				{artJPG && <source type='image/jpeg' srcSet={artJPG} />}
				<img
					ref={imgRef}
					data-src={artWEBP || artPNG || artJPG}
					loading='lazy'
					alt={altTitle}
					style={{...imgStyle, ...style}}
					onLoad={handleImageLoad}
					draggable={false}
					className={`w-full h-full object-contain pointer-events-none select-none touch-none transition-all duration-500 ${
						isFullyLoaded ? 'opacity-100 blur-0' : 'opacity-70 blur-sm'
					} ${className}`}
					onContextMenu={(e) => e.preventDefault()}
				/>
			</picture>
			{(!isFullyLoaded || isLoading) && (
				<div className='absolute inset-0 flex items-center min-w-[50px] min-h-[50px] justify-center'>
					<img
						src={thumbnail}
						loading='lazy'
						alt={altTitle}
						style={{...imgStyle, ...style}}
						draggable={false}
						className={`pointer-events-none select-none touch-none w-screen h-screen object-contain transition-all duration-500 blur-md ${className}`}
						onContextMenu={(e) => e.preventDefault()}
					/>
					<img src='/Loading.svg' alt='Loading...' className='absolute inset-0 m-auto p-1 w-20 h-auto rounded-lg bg-white aspect-square' />
				</div>
			)}
		</div>
	)
}

export default ImageLoader
