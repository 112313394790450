import {useLayoutEffect} from 'react'

// // Usage
// function App() {
//   // State for our modal
//   const [modalOpen, setModalOpen] = useState(false);

//   return (
//     <div>
//       <button onClick={() => setModalOpen(true)}>Show Modal</button>
//       <Content />
//       {modalOpen && (
//         <Modal
//           title="Try scrolling"
//           content="I bet you you can't! Muahahaha 😈"
//           onClose={() => setModalOpen(false)}
//         />
//       )}
//     </div>
//   );
// }

// function Modal({ title, content, onClose }) {
//   // Call hook to lock body scroll
//   useLockBodyScroll();

//   return (
//     <div className="modal-overlay" onClick={onClose}>
//       <div className="modal">
//         <h2>{title}</h2>
//         <p>{content}</p>
//       </div>
//     </div>
//   );
// }

// Hook
// export function useLockBodyScroll() {
// 	useLayoutEffect(() => {
// 		// Get original body overflow
// 		const originalStyle = window.getComputedStyle(document.body).overflow
// 		// Prevent scrolling on mount
// 		document.body.style.overflow = 'hidden'
// 		// Re-enable scrolling when component unmounts
// 		return () => (document.body.style.overflow = originalStyle)
// 	}, []) // Empty array ensures effect is only run on mount and unmount
// }

export const BlockScroll = () => {
	useLockBodyScroll()
	return <></>
}
export function useLockBodyScroll() {
	useLayoutEffect(() => {
		const rootElement = document.getElementById('root')
		if (!rootElement) return

		// Prevent scrolling on mount
		// rootElement.style.transition = `none 0s ease 0s`
		document.documentElement.style.overflowY = 'hidden'
		// Set document width to auto important
		document.documentElement.style.width = 'auto !important'

		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
		// Add padding to root to compensate for the scrollbar width
		rootElement.style.paddingRight = `${scrollbarWidth}px`
		// Re-enable scrolling when component unmounts
		return () => {
			document.documentElement.style.removeProperty('overflow-y')
			document.documentElement.style.removeProperty('width')
			// rootElement.style.removeProperty('overflow')
			// rootElement.style.removeProperty('transition')
			// rootElement.style.removeProperty('width')
			rootElement.style.removeProperty('padding-right')
		}
	}, []) // Empty array ensures effect is only run on mount and unmount
}
