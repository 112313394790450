import React, {useEffect, useRef} from 'react'
import {Image} from 'antd'
import lozad from 'lozad'

const LazyImageComponent = ({img}) => {
	const containerRef = useRef(null)

	useEffect(() => {
		if (containerRef.current) {
			// Target the inner <img> tag within the <Image> component and set data-src
			const imgElement = containerRef.current.querySelector('img')
			if (imgElement) {
				imgElement.setAttribute('data-src', img.webp || img.jpg || img.png)
				// imgElement.classList.add('lozad') // Add lozad class for observer

				// Initialize lozad on the specific image
				const observer = lozad(imgElement)
				observer.observe()
			}
		}
	}, [img])

	return (
		<div ref={containerRef}>
			<Image
				placeholder={() => {
					return (
						<>
							<div className='absolute inset-0 flex items-center min-w-[50px] min-h-[50px] justify-center'>
								<img
									src={img.thumb}
									loading='lazy'
									alt={'altTitle'}
									// style={{...imgStyle, ...style}}
									draggable={false}
									className={`pointer-events-none select-none touch-none w-screen h-screen object-contain transition-all duration-500 blur-md`}
									onContextMenu={(e) => e.preventDefault()}
								/>
								<img src='/Loading.svg' alt='Loading...' className='absolute inset-0 m-auto p-1 w-20 h-20 rounded-lg bg-white aspect-square' />
							</div>
						</>
					)
				}}
				preview={true}
				src='' // Set empty src initially for lazy loading
				alt={img.alt || img.name}
				rootClassName='img-element'
				className='h-full select-none max-w-xs aspect-square md:aspect-[3/4] object-cover'
				onContextMenu={(e) => e.preventDefault()}
			/>
		</div>
	)
}

export default LazyImageComponent
