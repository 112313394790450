import React, {useEffect, useState, useCallback, memo, useRef} from 'react'
import Draggable from 'react-draggable'
import {useSwipeable} from 'react-swipeable'
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import {ZoomInOutlined, ZoomOutOutlined} from '@ant-design/icons'
// import {useKeyPressEvent} from 'react-use'
import ImageLoader from '../../utils/imageLoader'
import {BlockScroll} from '../../hooks/useLockBodyScroll'
// import {useNotification} from '../../context/NotificationContext'

const ImageRenderOverlay = ({
	image,
	setIsPreviewOpen,
	zoomLevel,
	setZoomLevel,
	position,
	setPosition,
	handleImageChange,
	isLoading,
	setIsLoading,
}) => {
	const [imageDimensions, setImageDimensions] = useState({width: 0, height: 0})
	const lastTouchDistance = useRef(null)
	const [lastTap, setLastTap] = useState(0)

	const handleImageLoad = useCallback((e) => {
		setImageDimensions({
			width: e.target.clientWidth,
			height: e.target.clientHeight,
		})
	}, [])

	const handlers = useSwipeable({
		onSwiped: (eventData) => {
			if (zoomLevel === 1) {
				// eslint-disable-next-line default-case
				switch (eventData.dir) {
					case 'Left':
						handleImageChange(-1)
						break
					case 'Right':
						handleImageChange(1)
						break
					case 'Up':
					case 'Down':
						// onClose()
						break
				}
			}
		},
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
		delta: 50, // Minimum swipe distance (in px) before triggering
	})

	const handleZoom = useCallback(
		(delta) => {
			setZoomLevel((prev) => {
				const newZoom = prev + delta
				return Math.min(Math.max(newZoom, 1), 3)
			})
		},
		[setZoomLevel]
	)
	// const {setToast} = useNotification()
	const handleDoubleTap = useCallback(() => {
		setZoomLevel((prev) => {
			if (prev <= 1) return 1.5 // From base zoom to 1.5x
			if (prev <= 1.5) return 2 // From 1.5x to 2x
			return 1 // From any higher zoom back to 1x
		})
	}, [setZoomLevel])

	const handleTouchStart = useCallback(
		(e) => {
			if (e.touches.length === 2) return

			const currentTime = new Date().getTime()
			const tapLength = currentTime - lastTap
			if (tapLength < 300 && tapLength > 0) {
				e.preventDefault()
				handleDoubleTap()
			}
			setLastTap(currentTime)
		},
		[lastTap, handleDoubleTap]
	)

	const handleBackgroundClick = (e) => {
		if (e.target === e.currentTarget && e.type === 'click') {
			setIsPreviewOpen(false)
			console.log('clicked background')
			// onClose()
		}
	}
	const nodeRef = useRef(null)
	// console.log(imageDimensions)

	const handleTouchMove = useCallback(
		(e) => {
			if (e.touches.length === 2) {
				setLastTap(0)
			}

			if (e.touches.length !== 2) {
				lastTouchDistance.current = null
				return
			}

			const touch1 = e.touches[0]
			const touch2 = e.touches[1]
			const distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY)

			if (lastTouchDistance.current !== null) {
				const delta = distance - lastTouchDistance.current
				if (Math.abs(delta) > 10) {
					// Threshold to prevent tiny movements
					handleZoom(delta > 0 ? 0.1 : -0.1)
				}
			}

			lastTouchDistance.current = distance
		},
		[handleZoom]
	)

	return (
		<>
			<BlockScroll />
			<div
				className={`relative flex items-center justify-center duration-0 `}
				{...handlers}
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						handleBackgroundClick(e)
					}
				}}
				// onDoubleClick={handleDoubleTap}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}>
				<div
					className={`absolute inset-0 z-20`}
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							handleBackgroundClick(e)
						}
					}}></div>
				<Draggable
					nodeRef={nodeRef}
					disabled={zoomLevel <= 1}
					// bounds='parent'
					handle='.handle'
					position={position}
					onDrag={(_, data) => {
						if (zoomLevel <= 1) {
							setPosition({x: 0, y: 0})
						} else {
							setPosition({x: data.x, y: data.y})
						}
					}}
					onStop={(_, data) => {
						if (zoomLevel <= 1) {
							setPosition({x: 0, y: 0})
						} else {
							setPosition({x: data.x, y: data.y})
						}
					}}>
					<div
						ref={nodeRef}
						className={`handle relative duration-0 flex items-center justify-center z-30`}
						style={{
							transformOrigin: 'center center',
							width: imageDimensions.width ? `${imageDimensions.width * zoomLevel}px` : `${100 * zoomLevel}vw`,
							height: imageDimensions.height ? `${imageDimensions.height * zoomLevel}px` : `${100 * zoomLevel}vh`,
						}}>
						{isLoading && (
							<div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50'>
								<div className='loading-spinner'>Loading...</div>
							</div>
						)}
						<ImageLoader
							onLoad={handleImageLoad}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
							altTitle={image?.name}
							placeholder={image?.thumb}
							artPNG={image?.png}
							artWEBP={image?.full || image?.webp}
							className={`w-screen h-screen max-h-[95vh] max-w-[95vw]  align-middle select-none delay-0`}
							style={{
								transform: `scale(${zoomLevel})`,
								transition: 'none',
							}}
						/>
					</div>
				</Draggable>
			</div>
		</>
	)
}

const PreviewToolbarButton = memo(({onClick, disabled, Icon, text}) => {
	return (
		<button
			onClick={onClick}
			className={`flex flex-col mt-4 items-center w-16 h-16 rounded-full aspect-square justify-center select-none outline-white  ${
				disabled ? 'touch-none cursor-not-allowed text-gray-400 bg-black/10' : 'bg-black/30 backdrop-blur-md hover:outline'
			}`}
			disabled={disabled}>
			<Icon className='text-lg select-none touch-none pointer-events-none' />
		</button>
	)
})

const CustomToolbar = ({zoomLevel, setZoomLevel, setIsPreviewOpen, handleImageChange, progress}) => {
	const [arrowsVisible, setArrowsVisible] = useState(true)

	useEffect(() => {
		const keyHandlers = {
			ArrowLeft: () => handleImageChange(-1),
			ArrowRight: () => handleImageChange(1),
			Escape: () => setIsPreviewOpen(false),
		}

		const handleKeyPress = (e) => {
			const handler = keyHandlers[e.key]
			if (handler) handler()
		}

		window.addEventListener('keydown', handleKeyPress)
		return () => window.removeEventListener('keydown', handleKeyPress)
	}, [handleImageChange, setIsPreviewOpen])

	useEffect(() => {
		const timeout = setTimeout(() => setArrowsVisible(false), 3000)
		return () => clearTimeout(timeout)
	}, [arrowsVisible])

	const handleArrowInteraction = useCallback(() => {
		setArrowsVisible(true)
	}, [])

	return (
		<>
			<div className=''>
				<div className='flex flex-col rounded-full px-1 absolute bottom-0 right-4'>
					<button
						className={`custom-switch-left z-50 absolute top-1/2 -translate-y-1/2 left-2 
                            transition-opacity duration-1000 ${arrowsVisible ? 'opacity-100' : 'opacity-0'}`}
						onClick={() => handleImageChange(-1)}
						onMouseMove={handleArrowInteraction}
						onTouchStart={handleArrowInteraction}>
						<FaArrowLeft />
					</button>
					<button
						className={`custom-switch-right z-50 absolute top-1/2 -translate-y-1/2 right-2						
                            transition-opacity duration-1000 ${arrowsVisible ? 'opacity-100' : 'opacity-0'}`}
						onMouseMove={handleArrowInteraction}
						onTouchStart={handleArrowInteraction}
						onClick={() => handleImageChange(1)}>
						<FaArrowRight />
					</button>
					<PreviewToolbarButton
						onClick={() => {
							setZoomLevel((prevZoom) => {
								const newZoom = Math.min(prevZoom + 0.5, 3)
								return newZoom
							})
						}}
						disabled={zoomLevel >= 3}
						Icon={ZoomInOutlined}
						text={'Zoom In'}
					/>
					<PreviewToolbarButton
						onClick={() => {
							setZoomLevel((prevZoom) => {
								const newZoom = Math.max(prevZoom - 0.5, 1)
								return newZoom
							})
						}}
						disabled={zoomLevel <= 1}
						Icon={ZoomOutOutlined}
						text={'Zoom Out'}
					/>
					{/* <PreviewToolbarButton onClick={onReset} disabled={false} Icon={UndoOutlined} text={'Reset'} /> */}
				</div>
				<div className='backdrop-blur-md bg-black/30 rounded-full p-2 mb-1 absolute left-6 bottom-2 select-none touch-none w-max'>{progress}</div>
			</div>
		</>
	)
}

// Option 1: Named exports (recommended)
export {ImageRenderOverlay, CustomToolbar}
