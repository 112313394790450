import React from 'react'
// eslint-disable-next-line no-unused-vars
import {SecretaryStandby, TakingNotes} from '../../images'
import ContactBGPNG from '../../images/contact/ContactBG.png'
import ContactBGWebP from '../../images/contact/ContactBG.webp'
import {NavLink} from 'react-router-dom'
export const ContactSection = () => {
	return (
		<section className='z-contact relative bg-offwhite flex-to-center flex-col overflow-hidden mx-auto' id='contact'>
			<picture className='absolute z-0 w-full h-full'>
				<source srcSet={ContactBGWebP} type='image/webp' />
				<source srcSet={ContactBGPNG} type='image/png' />
				<img src={''} alt='Contact Background' className='w-full h-full object-cover object-center' />
			</picture>
			{/* <h1 className='text-5xl text-center lg:text-left p-6 pb-3 text-black font-lucky bg-white/80 backdrop-blur-sm rounded-lg z-10 my-12'>Contact</h1> */}
			<NavLink to='/contact?from=contact' className={'max-w-screen-xl'}>
				<div className='relative max-w-6xl flex items-center flex-col bg-white bg-white/80 backdrop-blur-sm rounded-lg z-10 px-6 py-10 my-12 mx-8'>
					<div className='font-lucky text-xl flex flex-col items-center'>
						<p>Click to</p>
						<p className='text-4xl'>Contact</p>
					</div>
					<div className='flex self-end relative pt-6 md:pt-0 max-w-[50vw]'>
						<div className={`doodle fill-black stroke-black z-10 h-[40rem] w-auto drawing`}>
							<TakingNotes />
						</div>
					</div>
				</div>
			</NavLink>
		</section>
	)
}
