import {Image} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
// eslint-disable-next-line no-unused-vars
// import ImageLoader from '../../utils/imageLoader'

import useScreenDetector from '../../hooks/useScreenDetector'
import LazyImageComponent from './LazyImageComponent'
import {ImageRenderOverlay, CustomToolbar} from './ImageRenderOverlay'

const startImages = 5
const addImages = 3

const GalleryGrid = ({images}) => {
	const imageRefs = useRef([])
	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const [previewIndex, setPreviewIndex] = useState(0)
	// New state for zoom level
	const [zoomLevel, setZoomLevel] = useState(1)
	const [visibleImages, setVisibleImages] = useState(startImages) // State to manage visible images

	// const [scale, setScale] = useState(1)
	// eslint-disable-next-line no-unused-vars
	const [position, setPosition] = useState({x: 0, y: 0})

	// Check if the device is mobile
	const {isMobile, isMinMobile} = useScreenDetector()

	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		// console.log('zoomLevel', zoomLevel)
		if (zoomLevel <= 1) setPosition({x: 0, y: 0})
	}, [zoomLevel])

	useEffect(() => {
		console.log('isPreviewOpen', isPreviewOpen)
	}, [isPreviewOpen])
	useEffect(() => {
		console.log('isPreviewOpen2', isPreviewOpen)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// New function to handle zooming
	// eslint-disable-next-line no-unused-vars
	// const handleZoom = (direction) => {
	// 	setZoomLevel((prevZoom) => {
	// 		const newZoom = direction === 'in' ? Math.min(prevZoom + 0.5, 3) : Math.max(prevZoom - 0.5, 1)
	// 		return newZoom
	// 	})
	// }

	// const handleBackgroundClick = (e) => {
	// 	if (e.target === e.currentTarget && e.type === 'click') {
	// 		console.log('clicked background')
	// 		// onClose()
	// 	}
	// }

	const scrollToImage = (index) => {
		if (imageRefs.current[index]) imageRefs.current[index].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
	}

	const handleShowMore = () => setVisibleImages((prev) => Math.min(prev + addImages, images.length)) // Show 3 more images

	const handleShowLess = () => setVisibleImages((prev) => Math.max(prev - addImages, addImages)) // Show 3 less images, but not less than 3

	const handleImageChange = (direction) => {
		setIsLoading(true)
		setPreviewIndex((prevIndex) => {
			const newIndex = (prevIndex + direction + images.length) % images.length
			scrollToImage(newIndex)
			setZoomLevel(1)
			setPosition({x: 0, y: 0})
			return newIndex
		})
	}
	return (
		<>
			{/* {isPreviewOpen ? <BlockScroll /> : <></>} */}
			<div className='image-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
				<Image.PreviewGroup
					preview={{
						visible: isPreviewOpen,
						// current: previewIndex,
						onVisibleChange: (visible, prev, current) => {
							console.log('onVisibleChange', visible)
							setIsPreviewOpen(visible)
							setZoomLevel(1)
							setPosition({x: 0, y: 0})
							if (!visible) {
								setPreviewIndex(0)
							} else {
								setPreviewIndex(current) // Update the preview index directly
								scrollToImage(current)
							}
						},
						// onChange: handleImageChange,
						// onVisibleChange: (visible) => setIsPreviewOpen(visible),
						destroyOnClose: true,
						loading: true,
						toolbarRender: () => (
							<CustomToolbar
								zoomLevel={zoomLevel}
								setZoomLevel={setZoomLevel}
								isPreviewOpen={isPreviewOpen}
								setIsPreviewOpen={setIsPreviewOpen}
								handleImageChange={handleImageChange}
								progress={`${previewIndex + 1} / ${images.length}`}
							/>
						),
						// onTransform: handleTransform,
						// imageRender: customImageRender,
						imageRender: () => (
							<ImageRenderOverlay
								image={images[previewIndex]}
								setIsPreviewOpen={setIsPreviewOpen}
								setPreviewIndex={setPreviewIndex}
								setZoomLevel={setZoomLevel}
								zoomLevel={zoomLevel}
								position={position}
								setPosition={setPosition}
								handleImageChange={handleImageChange}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
							/>
						),
					}}>
					{images.slice(0, isMobile ? visibleImages : 9).map((img, index) => {
						return (
							<div key={index} ref={(el) => (imageRefs.current[index] = el)} className={`image-grid-item group select-none `}>
								<LazyImageComponent img={img} />
							</div>
						)
					})}
					{/* Show More button */}
					<div className='flex justify-center items-center'>
						{isMobile && visibleImages < images.length && (
							<button onClick={handleShowMore} className='show-more-button'>
								Show More
							</button>
						)}
						{(isMobile || isMinMobile) && visibleImages < images.length && visibleImages > addImages && (
							<span className='mx-2'>|</span> // Separator
						)}
						{(isMobile || isMinMobile) && visibleImages > addImages && visibleImages < images.length && (
							<button onClick={handleShowLess} className='show-less-button'>
								Show Less
							</button>
						)}
					</div>
				</Image.PreviewGroup>
			</div>
		</>
	)
}

export default GalleryGrid
