const source = (link, text) => `(source: <a href="${link}" target="_blank" rel="noopener noreferrer">${text}</a>)`

// eslint-disable-next-line no-unused-vars
const unused_faqs = [
	{
		q: 'What is a caricature?',
		a: `"a representation of a person that is exaggerated for comic effect."${source(
			'https://www.vocabulary.com/dictionary/caricature',
			'vocabulary.com'
		)}`,
	},
	{
		q: 'How to pronounce caricature?',
		a: `keh · ruh · kuh · chur${source('https://www.google.com/search?q=How+to+pronounce+caricature', 'google.com')}`,
	},
	{
		q: 'Is there a refund policy?',
		a: 'Once you have paid for your caricature, we do not offer refunds. However, if you are not satisfied with the final art piece, please let us know, and we will do our best to resolve the issue.',
	},
	{
		q: 'Can I request changes to the preview before paying?',
		a: 'Yes, you can request changes to the preview before making the payment. We want you to be completely satisfied with your caricature, so we are happy to make any necessary adjustments. However, please note that significant changes to the original request may incur additional fees.',
	},
	{
		q: 'What is a caricature?',
		a: `A caricature is a representation of a person that is exaggerated for comic effect. ${source(
			'https://www.vocabulary.com/dictionary/caricature',
			'vocabulary.com'
		)}`,
	},
	{
		q: 'How to pronounce caricature?',
		a: `The pronunciation is "keh-ruh-kuh-chur". ${source('https://www.google.com/search?q=How+to+pronounce+caricature', 'google.com')}`,
	},
	{
		q: 'Should some of these faq\'s be in a "see more" option?',
		a: 'let us know in the contact section (this question qont be on the final site, its for when we go over these questions and need to decide how to show them, if to show all, or just some with a "show more" button for whoever wants to open and read more...)',
	},
]
// eslint-disable-next-line no-unused-vars
const faqs_filtered = [
	{
		q: 'How do I order a digital caricature?',
		a: 'You can place an order by (selecting a tier and) filling out the contact form on our website. Alternatively, you can contact us directly through email or social media. We will ask for details about the person/people you want to be caricatured and any specific requests or preferences you have. After sending you a preview of the ready art, you can confirm and make payment through PayPal, Zelle, Cashapp, or a similar platform. Once the payment is made, we will send you the final art piece.',
	},
	{
		q: 'What file format will I receive the final art in?',
		a: 'You will receive the final art in a high-quality file format, with a resolution of 3000px by 3000px, and 300dpi (best quality for printing).',
	},
	{
		q: 'Do you do live events?',
		a: 'Of course, we do live events! Please contact us for more information on how we can bring the fun and excitement of caricatures to your live event.',
	},
	{
		q: 'How do I contact you for further questions or inquiries?',
		a: 'You can contact us through email (contact form below) or social media platforms like Instagram or Telegram. We will respond to your message as soon as possible.',
	},
	{
		q: 'Why did you choose to spell your studio name with a "z" instead of an "s"?',
		a: 'The "z" in Adelez Studio represents the personal touch and attention to detail that we put into each of my caricatures. It adds a memorable and unique twist to the spelling of our studio\'s name, reflecting the individuality and personalized approach we take with each client.',
	},
	{
		q: 'Can I order a caricature for a specific date?',
		a: 'Yes, you can request a specific delivery date for your caricature. There is a standard time frame for delivery, and early delivery is available for an additional fee.',
	},
	{
		q: 'What type of file do I get?',
		a: 'By default, you will receive a PNG file with a canvas size of 3000px by 3000px at 300dpi (this is perfect for printing). If you prefer a different size or file format, please let me know.',
	},
	{
		q: 'What are the options?',
		a: 'You have three main options: Simple, Standard, and Premium. These options can be further customized by adding special requests.',
	},
	{
		q: 'How do I place an order?',
		a: 'We are working to automate the ordering process, in the meantime you can contact me directly to start an order.',
	},
	{
		q: 'Who is the most awesome person?',
		a: "You are! and that's a fact. (hard to belive you reached the end of the FAQ list, thanks for reading through it :) )",
	},
]

const faq_v3 = [
	{
		q: 'How do I order my caricature?',
		a: 'You can press the order button or just contact us in the contact section to get the ball rolling! We will ask for details about the person/people you want to be caricatured, to get a better understanding to make your caricature the best it can be (i.e., pictures, any specific requests or preferences, etc.). After 3-7 days, your art piece will be done and sent to you once payment is confirmed. If printed, shipping time may vary according to location.',
	},
	{
		q: 'What payment methods do we accept?',
		a: 'We accept payment through PayPal or Zelle.',
	},
	{
		q: 'Do you do custom-made greeting cards?',
		a: 'Yes, we do! We offer a custom caricature card tailored specifically for your loved one, telling their story in a fun, creative way for that extra cherry on top—a gift they will never forget. The best part? This complimentary card is included with every purchase of a printed caricature, so it’s 100% free!',
	},
	{
		q: 'In which format will I receive the final artwork?',
		a: 'You will receive the final art in a high-quality file format, usually size A4 (8.3 x 11.7 inches) and 300dpi (best quality for printing). If you want the piece printed, we offer different sizes as well (depending on the details).',
	},
	{
		q: 'Can I order a printed caricature?',
		a: 'Yes! We offer top-tier art prints with a variety of different printing options, plus worldwide free shipping to your doorstep!',
	},
	{
		q: 'Can I order a caricature for a specific date?',
		a: 'Yes, you can request a specific delivery date for your caricature. The standard time frame for delivery is 3-12 days; early delivery is available for an additional fee.',
	},
	{
		q: 'Do you do live events?',
		a: 'Of course, we do live events! Please contact us for more information on how we can bring the fun and excitement of caricatures to your event!',
	},
	{
		q: 'How do I contact you for further questions or inquiries?',
		a: 'You can contact us through email (contact form below) or social media platforms like Instagram or Telegram. We will respond to your message as soon as possible.',
	},
	{
		q: 'What if I have a question not answered by the FAQ?',
		a: "You can say ‘abra-caricature’, which will open an answer vortex with all the information you ever wanted, or you can contact us. We'll do our best to answer, maybe even better than the vortex can.",
	},
	{
		q: 'Who is the most awesome person?',
		a: "You are! And that's a fact. (Hard to believe you reached the end of the FAQ list, thanks for reading through it :) )",
	},
]

export default faq_v3
