import React from 'react'

import {isProduction} from '../utils/config'

//contact secretary
import {ReactComponent as SecretaryStandbyDarkDress} from './contact/secretary/Standby_DarkDress.svg'
import {ReactComponent as SecretaryStandbyLineDress} from './contact/secretary/Standby_LineDress.svg'
import {ReactComponent as SendNoteDarkDress} from './contact/secretary/SendNote_DarkDress.svg'
import {ReactComponent as SendNoteLineDress} from './contact/secretary/SendNote_LineDress.svg'
import {ReactComponent as TakingNotesDarkDress} from './contact/secretary/TakingNotes_DarkDress.svg'
import {ReactComponent as TakingNotesLineDress} from './contact/secretary/TakingNotes_LineDress.svg'
// const requireContext = require('require-context')
// eslint-disable-next-line no-unused-vars
const baseUrl = process.env.ASSETS_BASE_URL | 'https://assets.adelezstudio.com'
// eslint-disable-next-line no-unused-vars
const serveFromLocal = isProduction ? false : true

// eslint-disable-next-line
// function importAll(r, asArray = true) {
// 	let imagesArray = []
// 	r.keys().forEach((item) => {
// 		imagesArray.push(r(item))
// 	})
// 	return imagesArray
// }

// eslint-disable-next-line
function importAll(r, asArray = true) {
	const images = {}
	const fileTypes = ['webp', 'png', 'jpeg', 'jpg']

	r.keys().forEach((key) => {
		const parts = key.split('/')
		const fileName = parts[1]
		const fileType = fileTypes.find((type) => key.endsWith(type))

		// Skip folders that start with "hide"
		if (fileName.startsWith('hide')) {
			return
		}

		// Check if the file is one subfolder deep
		if (parts.length === 3 && fileType) {
			// Ensure it's one subfolder deep
			const folderName = parts[1] // Use the folder name for grouping
			if (!images[folderName]) {
				images[folderName] = {name: folderName}
			}
			// Check for special cases for thumbnail and full images
			if (fileName === 'thumbnail' && fileType === 'webp') {
				//this doesnt seem to work
				images[folderName]['thumb'] = r(key) // Assign as thumbnail
			} else if (fileName === 'full' && fileType === 'webp') {
				images[folderName]['full'] = r(key) // Assign as full image
			} else if (!images[folderName][fileType]) {
				images[folderName][fileType] = r(key) // Assign the first found image of the corresponding type
			}
		}
	})

	// Check for thumbnail in each folder
	// Check for full image in each folder
	Object.keys(images).forEach((folderName) => {
		const thumbnailKey = `./${folderName}/thumbnail.webp`
		if (r.keys().includes(thumbnailKey)) {
			images[folderName]['thumb'] = r(thumbnailKey) // Assign the thumbnail if it exists
			// console.log('importAll thumb2', folderName, images[folderName]['thumb'])
		}
		const fullImageKey = `./${folderName}/full.webp`
		if (r.keys().includes(fullImageKey)) {
			images[folderName]['full'] = r(fullImageKey) // Assign the full image if it exists
		}
	})

	// Filter out images that do not have any types
	Object.keys(images).forEach((folderName) => {
		const types = Object.keys(images[folderName])
		if (types.length === 1 && types[0] === 'name') {
			delete images[folderName] // Remove if only the name is present
		}
	})

	// console.log('importAll', images)
	if (asArray) {
		return Object.values(images)
	} else {
		return images
	}
}

const basicGalleryImages = importAll(require.context('./gallery/basic', true, /\.(png|jpe?g|svg|png|webp)$/))
const standardGalleryImages = importAll(require.context('./gallery/standard', true, /\.(png|jpe?g|svg|png|webp)$/))
const premiumGalleryImages = importAll(require.context('./gallery/premium', true, /\.(png|jpe?g|svg|png|webp)$/))
// const customGalleryImages = importAll(require.context('./caricatures/custom', false, /\.(png|jpe?g|svg)$/))

const caricatures = [...basicGalleryImages, ...standardGalleryImages, ...premiumGalleryImages] // ...customGalleryImages
export {caricatures, basicGalleryImages, premiumGalleryImages, standardGalleryImages} //customGalleryImages

export const getRandomCaricature = (amount = 1) => {
	const savedCaris = []
	const data = []
	const maxTry = caricatures.length
	for (let i = 1, m = 1; i <= amount && m <= maxTry; i++, m++) {
		const randomNumber = Math.floor(Math.random() * (maxTry - 1) + 1)

		if (m === maxTry) console.log('m = maxTry reached in getRandomCaricature')
		if (savedCaris.includes(randomNumber)) i--
		else {
			data.push(caricatures[randomNumber])
			savedCaris.push(randomNumber)
		}
	}

	return data
}

//how it works
export {default as howStep1} from './howitworks/HowItWorks_mobile1.png'
export {default as howStep2} from './howitworks/HowItWorks_mobile2.png'
export {default as howStep3} from './howitworks/HowItWorks_mobile3.png'
export {default as howDesktop} from './howitworks/HowItWorks_desktop.png'
export {ReactComponent as GreenArrowDown} from './howitworks/GreenArrowDown.svg'

//other
export {ReactComponent as Logo} from './other/logo.svg'
export {ReactComponent as LogoTxt} from './other/logo_txt.svg'

//pros section
export {default as marketingArt} from './pros/Pros_Marketing.png'
export {default as meaningfulTouchArt} from './pros/Pros_PersonalTouch.png'
export {default as updateSocialArt} from './pros/Pros_SocialMedia.png'
export {default as unforgettableGiftArt} from './pros/Pros_UnforgettableGift.png'
export {ReactComponent as marketingDoodle} from './pros/pros_icon-cash.svg'
export {ReactComponent as meaningfulTouchDoodle} from './pros/pros_icon-heart.svg'
export {ReactComponent as unforgettableGiftDoodle} from './pros/pros_icon-present.svg'
export {ReactComponent as updateSocialDoodle} from './pros/pros_icon-profile.svg'

//gallery
export {ReactComponent as ForGallery} from './gallery/doodles/ForGallery.svg'

export {default as Gallery_ShowBoy_sm} from './gallery/doodles/Gallery_ShowBoy_sm.webp'
export {default as Gallery_ShowBoy_lg} from './gallery/doodles/Gallery_ShowBoy_lg.webp'

export {default as GalleryBlackWhiteLeft} from './gallery/doodles/GalleryBlackWhiteLeft.webp'
export {default as GalleryBlackWhiteRight} from './gallery/doodles/GalleryBlackWhiteRight.webp'

export {default as GalleryColorLeft} from './gallery/doodles/GalleryColorLeft.webp'
export {default as GalleryColorRight} from './gallery/doodles/GalleryColorRight.webp'

export {default as GalleryPainterlyLeft} from './gallery/doodles/GalleryPainterlyLeft.webp'
export {default as GalleryPainterlyRight} from './gallery/doodles/GalleryPainterlyRight.webp'

//enough talk
// export {default as EnoughTalk_BottomDoodle_D} from './enoughtalk/EnoughTalk_BottomDoodle_D.png'
// export {default as EnoughTalk_BottomDoodle_M} from './enoughtalk/EnoughTalk_BottomDoodle_M.png'
// export {default as EnoughTalk_ExcitedWomen_D} from './enoughtalk/EnoughTalk_ExcitedWomen_D.png'
// export {default as EnoughTalk_ExcitedWomen_M} from './enoughtalk/EnoughTalk_ExcitedWomen_M.png'

// export {ReactComponent as AKCariSitting} from './about/AKCariSitting.svg'
// export {ReactComponent as DoodleOnText} from './about/DoodleOnText.svg'

// footer
export {ReactComponent as CopyCop} from './footer/CopyCop.svg'
export {ReactComponent as CopyCopMin} from './footer/CopyCop.min.svg'

// contact
export {default as ContactBG} from './contact/ContactBG.png'

const lineOrDark = Math.random() * 2 > 1
console.log('using random secretary dress style true=lines, false=dark : ', lineOrDark)

export const SendNote = () => (lineOrDark ? <SendNoteLineDress /> : <SendNoteDarkDress />)
export const SecretaryStandby = () => (lineOrDark ? <SecretaryStandbyLineDress /> : <SecretaryStandbyDarkDress />)
export const TakingNotes = () => (lineOrDark ? <TakingNotesLineDress /> : <TakingNotesDarkDress />)
// eslint-disable-next-line
export default {SendNote, SecretaryStandby, TakingNotes}
