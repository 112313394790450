const removeSprites = () => {
	const all_sprites = document.querySelectorAll('.sprite')
	all_sprites.forEach((sprite) => {
		sprite.remove()
	})
}

const removeNoScript = () => {
	const all_noscript = document.querySelectorAll('noscript')
	all_noscript.forEach((noscript) => {
		noscript.remove()
	})
}

const removeHtmlComments = () => {
	const iterator = document.createNodeIterator(document.documentElement, NodeFilter.SHOW_COMMENT)
	let comment
	while ((comment = iterator.nextNode())) {
		comment.remove()
	}
}

export {removeSprites, removeNoScript, removeHtmlComments}
