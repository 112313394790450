import React, {useState, useCallback, useRef, useContext, useEffect} from 'react'
// eslint-disable-next-line no-unused-vars
import {NavLink, useLocation} from 'react-router-dom'
import {Logo, LogoTxt} from '../../images'
import {useEventListener} from '../../hooks/useEventListener'
import './nav.css'
import {LoadingContext, NavContext} from '../../pages'
import useScreenDetector from '../../hooks/useScreenDetector'

// eslint-disable-next-line no-unused-vars
const TempOverLay = () => {
	const {isMobile, isMinMobile, isTablet, isMinDesktop, isDesktop, width, height} = useScreenDetector()

	return (
		<div className='fixed z-50 bg-cyan-400/80 bottom-0 left-0 hover:opacity-40'>
			<table className='table-auto border-collapse border border-slate-400'>
				<tbody className='odd:bg-white even:bg-slate-500'>
					<tr>
						<td>{'isMinMobile width < 360'}</td>
						<td className={isMinMobile ? 'bg-green-500' : ''}>{isMinMobile.toString()}</td>
						{/* <td>{width < 360 ? 'Yes' : 'No'}</td> */}
					</tr>
					<tr>
						<td>{'isMobile width >= 360 & < 768'}</td>
						<td className={isMobile ? 'bg-green-500' : ''}>{isMobile.toString()}</td>
						{/* <td>{width >= 360 && width < 768 ? 'Yes' : 'No'}</td> */}
					</tr>
					<tr>
						<td>{'isTablet width >= 768 & < 1000  '}</td>
						<td className={isTablet ? 'bg-green-500' : ''}>{isTablet.toString()}</td>
						{/* <td>{width >= 768 && width < 1000 ? 'Yes' : 'No'}</td> */}
					</tr>
					<tr>
						<td>{'isMinDesktop width > 1000 & <= 1140'}</td>
						<td className={isMinDesktop ? 'bg-green-500' : ''}>{isMinDesktop.toString()}</td>
						{/* <td>{width > 1000 && width <= 1140 ? 'Yes' : 'No'}</td> */}
					</tr>
					<tr>
						<td>{'isDesktop width > 1140'}</td>
						<td className={isDesktop ? 'bg-green-500' : ''}>{isDesktop.toString()}</td>
						{/* <td>{width > 1140 ? 'Yes' : 'No'}</td> */}
					</tr>
					<tr>
						<td className='flex justify-between'>
							<span className='px-2 bg-slate-100'>width</span>
							<span className='px-2 bg-slate-200'>{width}</span>
							{/* </td> 
					 <td></td>
			</tr>
			<tr> 
			 <td className='flex justify-between'> */}
							<span className='px-2 bg-slate-100'>height</span>
							<span className='px-2 bg-slate-200'>{height}</span>
						</td>
						<td></td>
					</tr>
					<tr>
						<td className='flex justify-between'>
							<span className='px-2 bg-slate-100'>ratio (width/height)</span>
							<span className='px-2 bg-slate-200'>{(width / height).toFixed(2)}</span>
						</td>
						<td></td>
					</tr>
					<tr>
						<td className='flex justify-between'>
							<span className='px-2 bg-slate-100'>ratio (height/width)</span>
							<span className='px-2 bg-slate-200'>{(height / width).toFixed(2)}</span>
						</td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
const Nav = () => {
	// const location = useLocation() // Get the current location
	const {onHeroSection, defaultShow, hideOnScroll} = useContext(NavContext) // Access the context
	const {isLoaded} = useContext(LoadingContext) // Use the context

	// useEffect(() => {
	// 	console.log({onHeroSection, defaultShow, hideOnScroll}) // Log context values for verification
	// }, [onHeroSection, defaultShow, hideOnScroll]) // Log only when these values change

	const [isScrolled, setIsScrolled] = useState(false)
	const [visibleNav, setVisibleNav] = useState(defaultShow)
	const prevScrollPos = useRef(0)

	useEffect(() => {
		setVisibleNav(defaultShow)
	}, [defaultShow])

	useEffect(() => {
		if (isLoaded) {
			const timer = setTimeout(() => {
				setVisibleNav(true) // Show the nav bar after 2 seconds when isLoaded is true
			}, 2000)

			return () => clearTimeout(timer) // Cleanup the timer on unmount
		}
	}, [isLoaded]) // Run this effect when isLoaded changes

	const handleScroll = useCallback(() => {
		const currentScrollPos = window.pageYOffset
		const isVisible = (prevScrollPos.current > currentScrollPos || currentScrollPos < 10) && isLoaded // Check isLoaded
		setVisibleNav(isVisible)
		setIsScrolled(currentScrollPos > 0)
		prevScrollPos.current = currentScrollPos
	}, [setVisibleNav, setIsScrolled, isLoaded]) // Add isLoaded to dependencies

	useEventListener('scroll', handleScroll, true)

	const MyNavLink = ({to, text}) => {
		// const currentPath = location.hash
		// const [isGallery, setIsGallery] = useState(currentPath === `#gallery`)
		const isToGallery = to === `/#gallery`
		// const prevPathRef = useRef(currentPath) // Store the previous path

		// Check if the current path has changed
		// useEffect(() => {
		// 	if (currentPath !== prevPathRef.current) {
		// 		console.log('MyNavLink currrent path updated')
		// 		// setIsGallery(currentPath === `#gallery`)
		// 		prevPathRef.current = currentPath // Update the previous path
		// 	}
		// }, [currentPath]) // Only run when currentPath changes

		return (
			<li className='' title={text}>
				<NavLink
					to={to}
					className={({isActive}) => `hover:text-e_blue 
				${isActive && !isToGallery ? 'text-blue-600 font-bold' : ''} 
				`}>
					{/* ${isGallery && isToGallery ? 'underline' : ''} */}
					{text}
				</NavLink>
			</li>
		)
	}
	const {isMobile} = useScreenDetector()

	return (
		<>
			<div
				className={`fixed top-0 left-0 w-full h-auto bg-white z-50 transition-all duration-300 ease-in-out ${
					hideOnScroll ? (visibleNav ? 'translate-y-0 shadow-md' : '-translate-y-full') : 'translate-y-0 shadow-md'
				} ${onHeroSection ? 'bg-opacity-40' : 'bg-opacity-70'}`}>
				<div className={`w-full backdrop-blur-md ${onHeroSection ? 'py-1 md:py-0' : 'py-4'}`}>
					<nav className='px-8 flex flex-col md:flex-row justify-center sm:justify-between items-center max-w-screen-xl mx-auto'>
						<NavLink to='/' title='Home'>
							<div className='logo'>
								<Logo alt='Knapp Cartoons' className={`a_sign ${isScrolled ? '' : ''} ${onHeroSection ? 'onHero' : ''}`} />
								<LogoTxt alt='Knapp Cartoons' className={`text ${isScrolled || isMobile ? 'hide' : ''} ${onHeroSection ? 'onHero' : ''}`} />
							</div>
						</NavLink>
						<ul className='flex space-x-4 text-xl font-lucky mt-2 sm:mt-1'>
							<MyNavLink to='/' text='Home' />
							<MyNavLink to='/about' text='About' />
							<MyNavLink to='/contact?from=nav' text='Contact' />
							<MyNavLink to='/#gallery' text='Gallery' />
						</ul>
					</nav>
				</div>
			</div>
			{/* <TempOverLay /> */}
		</>
	)
}

export default Nav

// export default React.memo(Nav, (prevProps, nextProps) => {
// 	const prevLocation = prevProps.location ? prevProps.location.pathname : ''
// 	const nextLocation = nextProps.location ? nextProps.location.pathname : ''
// 	return prevLocation === nextLocation // Only re-render on path change
// })
